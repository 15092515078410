/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/*---- General - Theme Styles ----*/
html {
  font-size: 16px;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #323232;
  padding-top: 155px;
}

p {
  line-height: 1.3em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

table {
  width: 100%;
}

a {
  color: #007ec5;
}

a,
.btn {
  transition: all 0.2s linear;
}
a:hover,
.btn:hover {
  text-decoration: none;
}
a:focus,
.btn:focus {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}

/* Helpers */
.overlay {
  background-color: rgba(45, 54, 68, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 40;
  transition: 0.3s all;
}

.notopmargin {
  margin-top: 0 !important;
}

.nobottommargin {
  margin-bottom: 0 !important;
}

.notoppadding {
  padding-top: 0 !important;
}

.noborder {
  border: none !important;
}

.notopborder {
  border-top: none !important;
}

.hidden-block {
  display: none;
}

.delimited {
  margin-bottom: 20px;
}

.distancer {
  height: 25px;
}
.distancer.distancer--short {
  height: 12px;
}
.distancer.distancer--long {
  height: 60px;
}

.grey {
  color: rgba(50, 50, 50, 0.85);
}

.greybg {
  background-color: #ecedef;
}

.intro {
  font-size: 0.9em;
  color: rgba(50, 50, 50, 0.85);
  line-height: 1.4em;
}

.badge {
  font-weight: 400;
  border-radius: 3px;
  padding: 4px 8px;
}
.badge.badge-success {
  background-color: rgba(0, 200, 83, 0.12);
  color: #00c853;
}
.badge.badge-danger {
  background-color: rgba(242, 71, 56, 0.12);
  color: #f24738;
}

.colorA {
  fill: #fd0a37 !important;
  transition: 0.2s all;
}
.colorA:hover {
  fill: #ec022d !important;
  stroke: #c80226 !important;
}

.colorB {
  fill: #ff9900 !important;
  transition: 0.2s all;
}
.colorB:hover {
  fill: #e68a00 !important;
  stroke: #c27400 !important;
}

.colorC {
  fill: #ffcc00 !important;
  transition: 0.2s all;
}
.colorC:hover {
  fill: #e6b800 !important;
  stroke: #c29b00 !important;
}

.colorD {
  fill: #228b22 !important;
  transition: 0.2s all;
}
.colorD:hover {
  fill: #1d771d !important;
  stroke: #165a16 !important;
}

.colorE {
  fill: #0c4a06 !important;
  transition: 0.2s all;
}
.colorE:hover {
  fill: #083204 !important;
  stroke: #031101 !important;
}

.colorY {
  fill: #2693ff !important;
  transition: 0.2s all;
}
.colorY:hover {
  fill: #0d86ff !important;
  stroke: #0074e8 !important;
}

.colorN {
  fill: #b20000 !important;
  transition: 0.2s all;
}
.colorN:hover {
  fill: #990000 !important;
  stroke: #750000 !important;
}

/* Buttons */
.btn {
  transition: all 0.2s linear;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  padding: 12px 25px;
  font-size: 14px;
  line-height: 1em;
  font-weight: 700;
  color: #fff;
}
.btn:hover {
  text-decoration: none;
  border: none;
}
.btn:focus {
  outline: none !important;
}
.btn.btn-primary,
.btn.btn-primary:focus {
  background-color: #007ec5;
  color: #fff;
}
.btn.btn-primary:hover,
.btn.btn-primary:focus:hover {
  background-color: #009ff8;
  color: #fff;
}
.btn.btn-secondary,
.btn.btn-secondary:focus {
  background-color: #fff;
  color: #323232;
}
.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:focus:hover,
.btn.btn-secondary:focus:active {
  box-shadow: 0 0 0 2px #fff;
  color: #323232;
}
.btn.btn--outline,
.btn.btn--outline:focus {
  background-color: transparent;
  color: #fff;
  box-shadow: inset 0 0 0 3px #fff;
}
.btn.btn--outline:hover,
.btn.btn--outline:focus:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 0 #fff, 0 0 0 2px #fff;
  color: #323232;
}
.btn.btn--wide {
  min-width: 240px;
}

.btn-helper {
  color: rgba(50, 50, 50, 0.45);
  display: inline-block;
  font-size: 13px;
  padding: 0 6px;
}
.btn-helper:hover {
  color: rgba(50, 50, 50, 0.9);
}
.btn-helper .icon-help {
  position: relative;
  top: 1px;
}

.btn--withicon i {
  margin-right: 6px;
  position: relative;
  font-size: 0.9em;
  top: 1px;
}

.btn--withlasticon i {
  margin-left: 8px;
  position: relative;
  max-height: 33px;
  top: 1px;
  margin-top: -2px;
  margin-bottom: -2px;
  transition: 0.2s ease-out;
  display: inline-block;
}
.btn--withlasticon .lenicon-arrow {
  position: relative;
  top: 0;
  font-size: 0.8em;
}
.btn--withlasticon:hover .lenicon-arrow,
.btn--withlasticon:hover .lenicon-arrow-alt {
  transform: translateX(5px);
}

/*---- Layout ----*/
.content {
  display: flex;
  height: 100%;
}

.year2017 .qrow--numericanswer,
.year2017 .tbl-numericanswers,
.year2017 .tbl-superhead-numercanswers {
  display: none;
}

.year2016 .tbl-superhead--2017only,
.year2016 .tbl-status--2017only,
.year2016 .qrow--2017only {
  display: none;
}

/*---- Page Header ----*/
.page-header {
  position: fixed;
  background-color: #fff;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  z-index: 5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  flex-direction: column;
}

.page-header__icon {
  margin-right: 8px;
}

.page-header__banner {
  margin-top: 8px;
  width: 100%;
}

.page-header__notification {
  flex: 1;
  margin: 0 30px;
  background-color: #2d78b3;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  justify-content: center;
}

.page-header__text {
  /* flex-grow: 1; */
  font-size: 14px;
  margin-right: 8px;
  line-height: 1;
  font-weight: bold;
}

.page-header__view-button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.page-header__view-button:hover {
  background-color: white;
  color: #2d78b3;
}

.page-header__row {
  justify-content: space-between;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-header > * {
  flex: 1;
}

.page-header__title {
  display: flex;
  align-items: center;
  height: 93px;
  padding: 0 0 12px 30px;
}
.page-header__title h1 {
  font-size: 1.2em;
  font-weight: 300;
  margin: 0;
}

.page-header__branding {
  padding: 8px 30px 8px 0;
  position: relative;
  z-index: 1;
  text-align: right;
  white-space: nowrap;
}
.page-header__branding > * {
  display: inline-block;
  margin-left: 25px;
}

.header__logo img {
  max-width: 130px;
  height: auto;
}

.header__logo--center {
  text-align: center;
}

.header__logo--right {
  text-align: right;
}

.page-heading {
  padding: 60px 0;
}
.page-heading h1 {
  margin: 0;
  line-height: 1.1em;
  font-weight: 300;
  font-size: 2.4em;
}

.btn-filters {
  position: fixed;
  bottom: 18px;
  left: 18px;
  z-index: 20;
  box-shadow: 0 3px 12px rgba(50, 50, 50, 0.1);
  display: none;
}

/*---- Filtering ----*/
.filters {
  background-color: #e3e6ea;
  padding: 30px;
  padding-bottom: 15px;
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.filters h1 {
  font-weight: 300;
  font-size: 1.82em;
  margin-bottom: 35px;
}
.filters h3 {
  font-size: 1.05em;
  margin: 0;
  margin-bottom: 15px;
  color: #51545e;
}
.filters .form .form-group {
  margin-bottom: 12px;
}
.filters .form .form-group > label {
  font-weight: 700;
  font-size: 0.85em;
  color: #717274;
  margin-bottom: 2px;
}
.filters .form .form-control {
  border-radius: 3px;
  height: 42px !important;
  font-weight: 700;
  box-shadow: none !important;
}

.filters__head {
  flex: 0 0 auto;
}

.filters__body {
  flex: 1 1 auto;
  overflow-y: auto;
}

.frow__year {
  flex: 0.25;
}

.form-question--2016 {
  display: none;
}
.form-question--2017 {
  display: none;
}
.form-question--2019 {
  display: none;
}
.nav-tabs--pills {
  display: flex;
  margin-top: 20px;
  margin-bottom: 8px;
}
.nav-tabs--pills .nav-link {
  display: block;
  padding-left: 8px;
  padding-right: 8px;
  background-color: rgba(50, 50, 50, 0.25);
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  border-radius: 0 !important;
  border: none;
}
.nav-tabs--pills .nav-link:hover {
  background-color: rgba(50, 50, 50, 0.35);
  color: #fff;
}
.nav-tabs--pills .nav-link.active {
  background-color: #fff;
  color: #007ec5;
  box-shadow: inset 0 2px 0 #007ec5;
}
.nav-tabs--pills .nav-item {
  flex: 1;
}
.nav-tabs--pills .nav-item:not(:first-child) .nav-link {
  border-left: 1px solid #e3e6ea !important;
}
.nav-tabs--pills .nav-item:first-child .nav-link {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.nav-tabs--pills .nav-item:last-child .nav-link {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.tab-content--regions {
  background-color: white;
  border: 1px solid rgba(50, 50, 50, 0.12);
  border-top: none;
  padding: 8px 12px;
  margin-top: -8px;
  margin-bottom: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn-closefilters {
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(50, 50, 50, 0.5);
  padding: 12px 14px;
  display: none;
}
.btn-closefilters:hover {
  color: rgba(50, 50, 50, 0.9);
}

.visualizationmode .tab-content--regions,
.visualizationmode .frow__filter--country {
  display: none;
}

.overviewmode .frow__question,
.overviewmode .nav-tabs--regions,
.overviewmode .tab-content--regions {
  /*display: none; */
}

/*---- Results ----*/
.results {
  background-color: #f8f9fa;
  width: calc(100% - 350px);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.results__nav {
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(50, 50, 50, 0.1);
  flex: 0 0 auto;
  position: relative;
  z-index: 2;
  align-items: center;
  padding: 0px 8px;
}
.results__nav .nav {
  justify-content: center;
}
.results__nav .nav-tabs {
  border-bottom-color: transparent;
  border-bottom: none !important;
}
.results__nav .nav-item {
  margin-right: 25px;
}
.results__nav .nav-link {
  padding: 12px 3px;
  border-radius: 0 !important;
  border-color: transparent !important;
  font-weight: 700;
  color: #4a5263;
  font-size: 0.92em;
}
.results__nav .nav-link:hover {
  color: #323232;
  border-bottom-color: #007ec5 !important;
}
.results__nav .nav-link.active {
  border-bottom-color: #007ec5 !important;
  box-shadow: inset 0 -3px #007ec5;
  color: #007ec5;
}

.flex {
  display: flex;
}

.head-subtitle {
  opacity: 0.5;
  margin-top: 8px;
  line-height: 1.5;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.highlight-info {
  color: #fe7a36;
  font-weight: 700;
}

.btn-print {
  height: 38px;
  /* position: absolute; */
  /* right: 10px;
  top: 5px; */
}

.results__content {
  padding: 60px 35px;
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
}
.results__content h2 {
  margin: 0;
  margin-bottom: 15px;
}
.results__content h3 {
  font-size: 1.6em;
  margin-top: 0;
}
.results__content h4 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 1.15em;
}

.print-head {
  display: none;
}

.results__inner {
  margin: 0 auto;
  max-width: 1100px;
}

.results-map {
  min-height: 500px;
  margin: 0 auto;
  border-radius: 4px;
}
.results-map svg {
  vertical-align: top;
}

.mapboxgl-popup {
  width: 340px;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  box-shadow: 0 15px 38px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.mapboxgl-popup-tip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.85);
}
.mapboxgl-popup-content {
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: #fff !important;
  margin-bottom: -10px;
}
.mapboxgl-popup-content h4 {
  font-size: 1.5em;
  margin: 0;
}
.mapboxgl-popup-content div {
  margin-top: 10px;
}

.mapboxgl-popup-content p {
  font-size: 1em;
  margin: 5px 0;
  font-size: 0.8em;
}

.map-tooltip {
  width: 340px;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  box-shadow: 0 15px 38px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: fixed;
  /* transition: 0.25s ease-out; */
  opacity: 0;
  visibility: hidden;
  padding: 15px 25px;
  /* transform: translateY(-100%); */
  z-index: 200;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.map-tooltip h4 {
  font-size: 1em;
  margin: 0;
}
.map-tooltip p {
  margin: 5px 0;
  font-size: 0.8em;
}
.map-tooltip.map-tooltip--on {
  opacity: 1;
  visibility: visible;
}

.map-tooltip__name {
  font-weight: 700;
}

.map-tooltip__inner {
  padding-top: 12px;
}

.mapBg {
  fill: transparent;
}

.country {
  fill: #b3bac5;
  stroke: #f8f9fa;
  stroke-width: 1;
  transition: 0.2s all;
}
.country:hover {
  fill: #576375;
  cursor: pointer;
}

.countryActive {
  fill: #007ec5;
  stroke: #f8f9fa;
  stroke-width: 1;
  transition: 0.2s all;
}
.countryActive:hover {
  fill: #576375;
  cursor: pointer;
}

.country-on {
  fill: #777;
}

.countryLabel {
  display: none;
  pointer-events: none;
}

.countryName {
  fill: #fff;
}

.countryLabelBg {
  fill: #222;
}

.results-map path#ZZZ {
  fill: transparent !important;
  stroke-dasharray: 1, 1 !important;
  pointer-events: none !important;
}

.results-map path#FFF {
  fill: transparent !important;
  stroke-width: 1px !important;
  pointer-events: none !important;
}

.results-map path#TTT {
  stroke-width: 0 !important;
  pointer-events: none !important;
}

.results-map path#NNN {
  pointer-events: none !important;
}

.results-map path#OOO {
  stroke-dasharray: 1, 1 !important;
  pointer-events: none !important;
}

.results-map path#PRK,
.results-map path#KOR,
.results-map path#EGY,
.results-map path#SDN,
.results-map path#SSD,
.results-map path#PAK,
.results-map path#IND {
  stroke-width: 0 !important;
}

.map-legend {
  margin-top: 35px;
  max-width: 1100px;
  margin: 0 auto;
}
.map-legend h4 {
  font-size: 1.4em;
  margin: 0;
  margin-bottom: 25px;
}
.map-legend .small {
  opacity: 0.5;
}

.list-legend {
  margin-bottom: 35px;
}
.list-legend > li {
  padding-left: 36px;
  position: relative;
  font-size: 0.85em;
  margin: 12px 0;
}
.list-legend > li .swatch {
  position: absolute;
  left: 0;
  margin-top: -3px;
}

.list-legend--noswatch > li {
  padding-left: 0;
}
.list-legend--noswatch .swatch {
  display: none;
}

.stats {
  display: flex;
  margin: 25px -12px;
  margin-bottom: 0;
  display: none;
}

.stat {
  padding: 0 12px;
  flex: 1;
  text-align: center;
  background-color: #fff;
  margin: 0 4px;
  padding: 12px 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.stat p {
  margin: 0;
}
.stat .stat__title {
  font-size: 0.8em;
  margin-bottom: 3px;
}
.stat .stat__value {
  font-size: 1.3em;
}

.swatch {
  width: 22px;
  height: 22px;
  border-radius: 3px;
  display: inline-block;
  background-color: rgba(164, 169, 186, 0.5);
}

.swatch--color1 {
  background-color: #fd0a37;
}

.swatch--color2 {
  background-color: #ff9900;
}

.swatch--color3 {
  background-color: #ffcc00;
}

.swatch--color4 {
  background-color: #228b22;
}

.swatch--color5 {
  background-color: #0c4a06;
}

.swatch--colory {
  background-color: #2693ff;
}

.swatch--colorn {
  background-color: #b20000;
}

.empty {
  text-align: center;
  padding: 35px;
}
.empty p {
  margin: 0;
  color: rgba(50, 50, 50, 0.35);
}

/*---- Table Results ----*/
.table--results {
  border-bottom: 1px solid #cfd4dc;
}
.table--results p {
  margin: 0;
  line-height: 1.15em;
}
.table--results h5 {
  margin: 0;
  font-size: 1.1em;
}
.table--results > thead > tr > th {
  border-top: none;
  border-bottom: none;
  padding-left: 10px;
  padding-right: 10px;
}
.table--results > thead > tr .tbl-superhead {
  padding-top: 25px;
  padding-bottom: 25px;
}
.table--results > thead > tr .tbl-supersubhead {
  padding-top: 18px;
  padding-bottom: 18px;
}
.table--results > thead > tr .tbl-head {
  background-color: #f7f9fd;
  color: #4a5263;
  font-size: 0.82em;
  vertical-align: middle;
  font-weight: 700;
  border-left: 1px solid #cfd4dc;
}
.table--results > thead > tr .tbl-head:last-child {
  border-right: 1px solid #cfd4dc;
}
.table--results > tbody > tr:hover > td {
  background-color: #f8f9fa;
}
.table--results > tbody > tr > td {
  font-weight: 700;
  border-top-color: #cfd4dc;
  border-left: 1px solid #cfd4dc;
  background-color: #fff;
  padding: 18px 10px;
  transition: 0.2s all;
}
.table--results > tbody > tr > td:last-child {
  border-right: 1px solid #cfd4dc;
}

.tbl-superhead {
  background-color: #007ec5;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tbl-supersubhead {
  background-color: #0071b1;
  color: #fff;
}

.list-filters {
  display: flex;
  margin: 0;
  font-size: 0.92em;
}
.list-filters > li {
  margin-right: 15px;
}

.tbl-status {
  min-width: 60px;
  text-align: center;
}

.tbl-statusa {
  background-color: #fd0a37 !important;
  color: #fff;
}

.tbl-statusb {
  background-color: #ff9900 !important;
  color: #fff;
}

.tbl-statusc {
  background-color: #ffcc00 !important;
  color: #fff;
}

.tbl-statusd {
  background-color: #228b22 !important;
  color: #fff;
}

.tbl-statuse {
  background-color: #0c4a06 !important;
  color: #fff;
}

.tbl-statusy {
  background-color: #2693ff !important;
  color: #fff;
}

.tbl-statusn {
  background-color: #b20000 !important;
  color: #fff;
}

.tbl-small {
  font-size: 0.8em;
}

.tbl-question {
  width: 90px;
  text-align: center;
}

.tbl-answercode {
  width: 90px;
  text-align: center;
}

.tbl-answers {
  padding: 0 !important;
}

.qrow {
  display: flex;
}
.qrow + .qrow {
  border-top: 1px solid #cfd4dc;
}
.qrow > * {
  padding: 18px 10px;
  flex: 0 0 auto;
}

.qrow__question {
  width: 90px;
  border-right: 1px solid #cfd4dc;
  text-align: center;
}

.qrow__answercode {
  width: 90px;
  border-right: 1px solid #cfd4dc;
  text-align: center;
}

.qrow__answer {
  flex: 1 1 auto;
}

.responsive-table--overview {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table--responseoverview:hover {
  cursor: move;
}
.table--responseoverview > thead > tr > th {
  text-align: center;
  line-height: 1.1em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.table--responseoverview > thead > tr > th:first-child {
  text-align: left;
  border-top-left-radius: 5px;
}
.table--responseoverview > thead > tr > th:last-child {
  border-top-right-radius: 5px;
}
.table--responseoverview > thead > tr > th:not(:first-child) {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.table--responseoverview > tbody > tr td {
  background-color: #fff;
}

.horizontalscroll {
  display: block;
  text-align: center;
  margin: 0;
  margin-bottom: 4px;
  padding: 8px 10px;
  font-size: 0.9em;
}
.horizontalscroll i {
  margin: 0 12px;
}
.horizontalscroll i:first-child {
  display: inline-block;
  transform: rotate(180deg);
}

.table-overview {
  position: relative;
}
.table-overview::before,
.table-overview::after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 70px;
  transition: 0.2s all;
  pointer-events: none;
  opacity: 0;
}
.table-overview::before {
  background: -moz-linear-gradient(
    left,
    #f8f9fa 0%,
    rgba(248, 249, 250, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    #f8f9fa 0%,
    rgba(248, 249, 250, 0) 100%
  );
  background: linear-gradient(
    to right,
    #f8f9fa 0%,
    rgba(248, 249, 250, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f9fa', endColorstr='#00f8f9fa',GradientType=1 );
}
.table-overview::after {
  left: auto;
  right: 0;
  background: -moz-linear-gradient(
    left,
    rgba(248, 249, 250, 0) 0%,
    #f8f9fa 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(248, 249, 250, 0) 0%,
    #f8f9fa 100%
  );
  background: linear-gradient(
    to right,
    rgba(248, 249, 250, 0) 0%,
    #f8f9fa 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f8f9fa', endColorstr='#f8f9fa',GradientType=1 );
}

.responsive-table--overview {
  max-width: 1100px;
  margin: 0 auto;
  /* overflow-x: auto; */
}
.responsive-table--overview > .table {
  min-width: 1800px;
}
.responsive-table--overview .tbl-country {
  width: 200px;
}
.responsive-table--overview p {
  font-weight: 700;
  margin: 0;
}
.responsive-table--overview .btn-status {
  font-weight: 700;
}

.flextable {
  font-weight: 700;
  margin-top: 45px;
}
.flextable .row {
  display: flex;
  margin: 0;
  padding: 0;
}
.flextable .col {
  padding: 0;
  margin: 0;
  flex: 1;
}
.flextable .col-head {
  width: 80px;
  flex: 0 0 auto;
  text-align: right;
}
.flextable .row--full {
  padding-left: 80px;
}
.flextable .row--full p {
  margin-bottom: 10px;
}
.flextable .row--headings {
  background-color: #fff;
}
.flextable .row--headings .col {
  border-left: 1px solid #cfd4dc;
  border-top: 1px solid #cfd4dc;
  border-bottom: 1px solid #cfd4dc;
  padding: 18px 12px;
  text-align: center;
}
.flextable .row--headings .col:last-child {
  border-right: 1px solid #cfd4dc;
}
.flextable .row--headings .col-head {
  padding-left: 12px;
  padding-right: 12px;
  text-align: right;
}
.flextable .row--body {
  background-color: #fff;
  margin-bottom: -1px;
}
.flextable .row--body .col {
  border-left: 1px solid #cfd4dc;
  border-bottom: 1px solid #cfd4dc;
  padding: 0 12px;
}
.flextable .row--body .col:last-child {
  border-right: 1px solid #cfd4dc;
}
.flextable .row--yesno .list-ratings .list-ratings--yesno {
  display: list-item;
}
.flextable .row--yesno .list-ratings .list-ratings--abc {
  display: none;
}
.flextable .list-ratings {
  margin: 12px 0;
}
.flextable .list-ratings > li {
  padding: 8px 0;
  min-height: 25px;
}
.flextable .list-ratings .list-ratings--yesno {
  display: none;
}
.flextable .list-numbers {
  display: flex;
  font-size: 0.8em;
  margin: 0 -12px;
  margin-right: 12px;
}
.flextable .list-numbers > li {
  flex: 1;
  position: relative;
}
.flextable .list-numbers > li:first-child {
  padding-left: 5px;
  text-align: left;
}
.flextable .col-data .list-ratings {
  margin: 12px -12px;
  position: relative;
  z-index: 2;
}
.flextable .col-data:not(:first-child) .list-ratings > li:nth-child(1) .bar {
  background-color: #fd0a37;
}
.flextable .col-data:not(:first-child) .list-ratings > li:nth-child(2) .bar {
  background-color: #ff9900;
}
.flextable .col-data:not(:first-child) .list-ratings > li:nth-child(3) .bar {
  background-color: #ffcc00;
}
.flextable .col-data:not(:first-child) .list-ratings > li:nth-child(4) .bar {
  background-color: #228b22;
}
.flextable .col-data:not(:first-child) .list-ratings > li:nth-child(5) .bar {
  background-color: #0c4a06;
}
.flextable .col-data:not(:first-child) .list-ratings > li .bar-unknown {
  background-color: #c0c0c0 !important;
}
/*.flextable .col-data:not(:first-child) .list-ratings--world > li .bar {*/
/*  background-color: #C0C0C0 !important; }*/
.flextable .col-label {
  font-size: 0.8em;
  margin: 25px 0;
}
.flextable .lines {
  position: relative;
  margin: 0 -12px;
  display: flex;
  height: 30px;
}
.flextable .lines span {
  height: 100%;
  width: 1px;
  display: inline-block;
  background-color: #cfd4dc;
  position: absolute;
}
.flextable .lines span p {
  margin: 0;
  font-size: 0.8em;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
}
.flextable .lines span:nth-child(1) {
  left: 20%;
}
.flextable .lines span:nth-child(2) {
  left: 40%;
}
.flextable .lines span:nth-child(3) {
  left: 60%;
}
.flextable .lines span:nth-child(4) {
  left: 80%;
}
.flextable .lines--full {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
.flextable .bar {
  height: 25px;
  background-color: #eee;
  left: 0;
  text-align: right;
  position: relative;
}
.flextable .bar p {
  position: absolute;
  left: 100%;
  line-height: 25px;
  font-size: 0.9em;
  padding: 0 3px;
}
.flextable .bar.bar-max p {
  right: 0px;
  left: auto;
}
.flextable .bar-col1:nth-child(1) {
  background-color: #c0c0c0;
}
.flextable .bar-col2 {
  background-color: #f08e39;
}
.flextable .bar-col3 {
  background-color: #df585c;
}
.flextable .bar-col4 {
  background-color: #78b7b2;
}
.flextable .bar-col5 {
  background-color: #5ba053;
}
.flextable .bar-col6 {
  background-color: #ecc854;
}
.flextable .bar-col7 {
  background-color: #af7ba0;
}
.flextable .bar-coly0,
.flextable .bar-coly1,
.flextable .bar-coly2,
.flextable .bar-coly3,
.flextable .bar-coly4,
.flextable .bar-coly5,
.flextable .bar-coly6 {
  background-color: #2693ff;
}
.flextable .bar-coln0,
.flextable .bar-coln1,
.flextable .bar-coln2,
.flextable .bar-coln3,
.flextable .bar-coln4,
.flextable .bar-coln5,
.flextable .bar-coln6 {
  background-color: #b20000;
}

.response-overview {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-out;
}
.response-overview:hover {
  box-shadow: 0 7px 18px rgba(50, 50, 50, 0.1);
}
.response-overview img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.btn-enlarge {
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: rgba(50, 50, 50, 0.8);
  color: #fff;
}
.btn-enlarge:hover {
  background-color: rgba(50, 50, 50, 0.9);
}

.list-docs {
  margin-top: 15px;
}
.list-docs a {
  display: block;
  padding: 12px 18px;
  padding-left: 50px;
  background-color: #fff;
  margin-bottom: 3px;
  color: #323232;
  position: relative;
  border-radius: 3px;
  font-size: 0.9em;
  font-weight: 700;
  line-height: 1.25em;
}
.list-docs a:hover {
  z-index: 1;
  color: #007ec5;
  box-shadow: 0 1px 8px rgba(50, 50, 50, 0.1);
}
.list-docs .whoicon-doc {
  position: absolute;
  left: 20px;
  top: 13px;
  font-size: 1.3em;
  color: #007ec5;
}

/*---- Footer ----*/
.page-footer {
  color: #4a5263;
  font-size: 0.85em;
  flex: 0 0 auto;
  padding-top: 15px;
}
.page-footer p {
  margin: 0;
}
.page-footer .page-footer__copy {
  margin-top: 5px;
}

.col-right ul {
  margin: 0;
  margin-bottom: 15px;
}
.col-right .btn-text {
  color: #4a5263;
}
.col-right .btn-text:hover {
  color: #323232;
}

.list-soc {
  display: inline-flex;
}
.list-soc > li {
  margin: 0 2px;
}
.list-soc a {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background-color: rgba(50, 50, 50, 0.2);
  font-size: 0.8em;
}
.list-soc a i {
  line-height: 25px;
}
.list-soc a:hover {
  opacity: 0.9;
}
.list-soc .fb {
  background-color: #3b5998;
}
.list-soc .tw {
  background-color: #55acee;
}
.list-soc .li {
  background-color: #0077b5;
}
.list-soc .go {
  background-color: #dc4e41;
}

/* Tooltip style */
.tooltipster-base {
  background-color: rgba(50, 50, 50, 0.87);
  border-radius: 4px;
  color: #fff;
  border: none;
  box-shadow: 0 5px 14px rgba(50, 50, 50, 0.1);
}
.tooltipster-base .tooltipster-content {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4em;
  text-align: left;
  padding: 30px 25px;
}

@font-face {
  font-family: 'whoicon';
  /* src: url("../webfonts/whoicon.eot?2z7v1y"); */
  src: url('../webfonts/whoicon.ttf?2z7v1y') format('truetype'),
    url('../webfonts/whoicon.woff?2z7v1y') format('woff'),
    url('../webfonts/whoicon.svg?2z7v1y#lenicon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='whoicon-'],
[class*=' whoicon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'whoicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.whoicon-doc:before {
  content: '\e907';
}

.whoicon-search:before {
  content: '\e903';
}

.whoicon-filters:before {
  content: '\e900';
}

.whoicon-arrow:before {
  content: '\e901';
}

.whoicon-arrow-alt:before {
  content: '\e902';
}

.whoicon-caret:before {
  content: '\e904';
}

.whoicon-chat:before {
  content: '\e905';
}

.whoicon-checkmark:before {
  content: '\e906';
}

.whoicon-facebook:before {
  content: '\e90c';
}

.whoicon-google:before {
  content: '\e90f';
}

.whoicon-instagram:before {
  content: '\e911';
}

.whoicon-linkedin:before {
  content: '\e912';
}

.whoicon-minus:before {
  content: '\e913';
}

.whoicon-phone:before {
  content: '\e915';
}

.whoicon-plus:before {
  content: '\e916';
}

.whoicon-refresh:before {
  content: '\e918';
}

.whoicon-settings:before {
  content: '\e919';
}

.whoicon-twitter:before {
  content: '\e91a';
}

.whoicon-warning:before {
  content: '\e91b';
}

.whoicon-x:before {
  content: '\e91c';
}

@media screen and (max-width: 1100px) {
  body {
    padding-top: 93px;
  }

  .header__logo img {
    max-width: 110px;
  }

  .page-header > * {
    flex: 1 1 auto;
    padding: 12px 18px;
  }

  .page-header__title h1 {
    font-size: 1.2em;
    max-width: 600px;
  }
  .page-header__title h1 br {
    display: none;
  }

  .page-header__branding {
    white-space: nowrap;
  }

  .filters {
    width: 300px;
    padding: 30px 18px;
  }
  .filters .form .form-control {
    font-size: 0.9em;
    height: 38px !important;
  }

  .results {
    width: calc(100% - 300px);
    flex: 0 0 auto;
  }

  .results__content {
    padding: 35px 25px;
  }

  .results__nav .nav-link {
    font-size: 0.85em;
  }

  .responsive-table {
    display: block;
    overflow-x: auto;
  }
  .responsive-table > .table {
    min-width: 840px;
  }
}
@media screen and (max-width: 1023px) {
  body {
    padding-top: 135px;
  }

  .page-header {
    flex-direction: column;
    z-index: 1;
  }
  .page-header .page-header__branding {
    order: 1;
  }
  .page-header .page-header__title {
    order: 2;
    text-align: center;
  }

  .page-header .page-header__title {
    height: auto;
  }

  .filters {
    position: fixed;
    left: -300px;
    top: 0;
    bottom: 0;
    z-index: 20;
    transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .filtersvisible .btn-filters {
    opacity: 0;
    visibility: hidden;
    transform: translateX(15px);
  }
  .filtersvisible .filters {
    left: 0;
    box-shadow: 4px 0 16px rgba(50, 50, 50, 0.1);
  }

  .btn-filters {
    display: inline-block;
  }

  .btn-closefilters {
    display: inline-block;
  }

  .results {
    flex: 1 1 auto;
    width: 100%;
  }

  .results__content h3 {
    font-size: 1.35em;
  }

  .results__nav {
    z-index: 2;
  }

  .tab-pane {
    padding-bottom: 85px;
  }
}
@media screen and (max-width: 767px) {
  body {
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }

  .page-header {
    flex: 0 0 auto;
    position: static;
  }

  .page-header__branding {
    width: 100%;
    padding: 8px 12px;
    display: flex;
  }
  .page-header__branding > * {
    display: initial;
    margin-left: 0;
    flex: 1;
  }
  .page-header__branding .header__logo--left {
    text-align: left;
  }
  .page-header__branding .header__logo--right {
    text-align: right;
  }

  .header__logo img {
    max-width: 90px;
  }

  .page-header__title {
    padding: 8px 14px;
    padding-top: 3px;
  }
  .page-header__title h1 {
    font-size: 1.2em;
  }

  .results__nav {
    display: flex;
  }
  .results__nav .container {
    padding: 0;
  }
  .results__nav .nav-item {
    margin: 0 4px;
    flex: 1;
  }
  .results__nav .nav-link {
    font-size: 0.7em;
    display: block;
    text-align: center;
    line-height: 1.1em;
    padding: 8px 3px;
    min-height: 42px;
    border-bottom: none;
  }
  .results__nav .nav-link.active {
    box-shadow: inset 0 -2px 0 #007ec5;
  }

  .results__content {
    padding: 25px 12px;
  }
  .results__content h3 {
    font-size: 1.15em;
  }

  .flextable--viz {
    width: 100%;
    overflow-x: auto;
    font-size: 0.95em;
  }
  .flextable--viz .row {
    min-width: 800px;
  }

  .table--results p {
    font-size: 0.85em;
  }

  .results-map {
    min-height: 240px;
  }

  .btn-filters {
    left: 10px;
    bottom: 10px;
  }

  .btn-print {
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .tab-documents span {
    display: none;
  }
}
/* @media print { */
@media print {
  body {
    -webkit-print-color-adjust: exact;
    padding-top: 0;
  }

  .page-header {
    flex-direction: column;
    position: static;
    border-bottom: none;
  }
  .page-header .page-header__branding {
    order: 1;
    width: 100%;
    text-align: center;
    padding: 15px 0;
  }
  .page-header .page-header__branding > * {
    padding: 0 32px;
    margin-left: 0;
  }
  .page-header .page-header__title {
    order: 2;
    width: 100%;
    text-align: center;
  }
  .page-header .page-header__title h1 {
    font-size: 30px;
  }
  .page-header .page-header__title h1 br {
    display: none;
  }

  .header__logo img {
    max-width: 140px;
  }

  .filters,
  .results__nav,
  .tab-pane--docs {
    display: none;
  }

  .results {
    background-color: #fff;
  }

  .results__content {
    overflow: visible;
  }

  .results__inner .tab-pane {
    display: block !important;
    opacity: 1;
    padding-bottom: 40px;
  }

  .swatch,
  .flextable .bar,
  .tbl-status {
    -webkit-print-color-adjust: exact;
  }

  .tbl-statusa {
    position: relative;
    line-height: 1em;
  }
  .tbl-statusa::before {
    content: 'A';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fd0a37;
    color: #fff;
    font-weight: 700;
    padding: 18px 10px;
  }

  .tbl-statusb {
    position: relative;
    line-height: 1em;
  }
  .tbl-statusb::before {
    content: 'B';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ff9900;
    color: #fff;
    font-weight: 700;
    padding: 18px 10px;
  }

  .tbl-statusc {
    position: relative;
    line-height: 1em;
  }
  .tbl-statusc::before {
    content: 'C';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffcc00;
    color: #fff;
    font-weight: 700;
    padding: 18px 10px;
  }

  .tbl-statusd {
    position: relative;
    line-height: 1em;
  }
  .tbl-statusd::before {
    content: 'D';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #228b22;
    color: #fff;
    font-weight: 700;
    padding: 18px 10px;
  }

  .tbl-statuse {
    position: relative;
    line-height: 1em;
  }
  .tbl-statuse::before {
    content: 'E';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #0c4a06;
    color: #fff;
    font-weight: 700;
    padding: 18px 10px;
  }

  .tbl-superhead,
  .tbl-supersubhead {
    position: relative;
  }
  .tbl-superhead::before,
  .tbl-supersubhead::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #007ec5;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .tbl-superhead h5,
  .tbl-superhead ul,
  .tbl-superhead p,
  .tbl-supersubhead h5,
  .tbl-supersubhead ul,
  .tbl-supersubhead p {
    position: relative;
    z-index: 2;
  }

  .tbl-supersubhead::before {
    background-color: #0071b1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .print-head {
    display: block;
  }

  .responsive-table--overview {
    overflow-x: visible;
  }
  .responsive-table--overview > .table {
    min-width: inherit;
  }

  .table--responseoverview p {
    font-size: 9px;
  }
  .table--responseoverview .tbl-status {
    font-size: 9px;
  }
  .table--responseoverview th,
  .table--responseoverview td {
    padding: 12px 8px;
  }
  .table--responseoverview .tbl-status {
    padding: 0;
  }
}

.page-notice {
  display: none;
  font-weight: 700;
  color: #4a5263;
  font-size: 0.92em;
  text-align: center;
  margin-bottom: 20px;
}

.year2018.visualizationmode .page-notice {
  display: block;
}

.year2018.tableviewmode .page-notice {
  display: block;
}

.year2018.overviewmode .page-notice {
  display: block;
}

.year2018.visualizationmode .results__content {
  padding-top: 18px;
}

.year2018.tableviewmode .results__content {
  padding-top: 18px;
}

.year2018.overviewmode .results__content {
  padding-top: 18px;
}

.tbl-status--2017only {
  display: none;
}

.year2017 .tbl-status--2017only {
  display: table-cell;
}

/*# sourceMappingURL=style.css.map */
