@import 'bootstrap.min.css';
@import 'tooltipster.css';

#root {
  height: calc(100vh - 94px);
  overflow: hidden;
}

@import 'style.css';

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}

.flextable .list-ratings .list-ratings--yesno {
  display: list-item;
}

.list-ratings.yes-no li > div {
  background-color: #2693ff !important;
}

.list-ratings.yes-no li:last-child > div {
  background-color: #b20000 !important;
}

.swatch--A {
  background-color: rgba(253, 10, 55, 0.5);
}

.swatch--B {
  background-color: rgba(255, 153, 0, 0.5);
}

.swatch--C {
  background-color: rgba(255, 204, 0, 0.5);
}

.swatch--D {
  background-color: rgba(34, 139, 34, 0.5);
}

.swatch--E {
  background-color: rgba(12, 74, 6, 0.5);
}

.swatch--Yes {
  background-color: rgba(38, 147, 255, 0.5);
}

.swatch--No {
  background-color: rgba(178, 0, 0, 0.5);
}

.country--color-A {
  fill: #fd0a37 !important;
  transition: 0.2s all;
}
.country--color-A:hover {
  fill: #ec022d !important;
  stroke: #c80226 !important;
}

.country--color-B {
  fill: #ff9900 !important;
  transition: 0.2s all;
}
.country--color-B:hover {
  fill: #e68a00 !important;
  stroke: #c27400 !important;
}

.country--color-C {
  fill: #ffcc00 !important;
  transition: 0.2s all;
}
.country--color-C:hover {
  fill: #e6b800 !important;
  stroke: #c29b00 !important;
}

.country--color-D {
  fill: #228b22 !important;
  transition: 0.2s all;
}
.country--color-D:hover {
  fill: #1d771d !important;
  stroke: #165a16 !important;
}

.country--color-E {
  fill: #0c4a06 !important;
  transition: 0.2s all;
}
.country--color-E:hover {
  fill: #083204 !important;
  stroke: #031101 !important;
}

.country--color-Yes {
  fill: #2693ff !important;
  transition: 0.2s all;
}
.country--color-Yes:hover {
  fill: #0d86ff !important;
  stroke: #0074e8 !important;
}

.country--color-No {
  fill: #b20000 !important;
  transition: 0.2s all;
}
.country--color-No:hover {
  fill: #990000 !important;
  stroke: #750000 !important;
}

.stattable-head {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 16px;
}

.stattable-head > .extra-legend {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
}

.flextable--centered {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.tbl-status--unknown {
  background-color: #c0c0c0 !important;
  color: #fff;
}

.tbl-status--A {
  background-color: #fd0a37 !important;
  color: #fff;
}

.tbl-status--B {
  background-color: #ff9900 !important;
  color: #fff;
}

.tbl-status--C {
  background-color: #ffcc00 !important;
  color: #fff;
}

.tbl-status--D {
  background-color: #228b22 !important;
  color: #fff;
}

.tbl-status--E {
  background-color: #0c4a06 !important;
  color: #fff;
}

.tbl-status--Yes,
.tbl-status--Y {
  background-color: #2693ff !important;
  color: #fff;
}

.tbl-status--No,
.tbl-status--N {
  background-color: #b20000 !important;
  color: #fff;
}

.qrow__answercode {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 0;
}

.qrow__answercode p {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 64px;
}

.qrow__question {
  padding: 0 18px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.table--results .qrow__question p {
  display: block;
  width: 100%;
}

.qrow__answer {
  padding: 0 10px;
  /* justify-content: center; */
  align-items: center;
  display: flex;
}

.qrow__answer > .tbl-small {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.tableview-single-q .qrow__answercode {
  height: 80px;
}

.tableview-single-q .qrow__answercode p {
  line-height: 80px;
}

.table--results > tbody.tableview-single-q > tr > td {
  vertical-align: middle;
}

.response-overview {
  /* overflow-y: auto; */
  padding: 0 30px;
  overflow: visible;
}

.tableview--tab {
  padding: 0 30px;
}

.visualizationview--tab {
  padding: 0 30px;
}

.response-overview .horizontalscroll {
  margin-top: 60px;
}

.responsive-table--overview {
  margin: 0 auto;
}

.response-overview:hover {
  box-shadow: none !important;
}

.responsive-table--overview span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.response--overview--table {
  display: table;
}

.response--overview-table-row {
  display: table-row;
}

.response--overview-table-cell {
  display: table-cell;
  border-bottom: 1px solid #ddd;
  background-color: white;
  vertical-align: middle;
  height: 45px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-right: 1px solid transparent;
}

.response--overview-table-cell p {
  width: 90px;
}

.response--overview-table-cell.tbl-country p {
  width: 200px;
}

.response--overview-table-cell.response--overview-table-header {
  background-color: #007ec5;
  text-align: center;
  height: 70px;
  color: white;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

/* .response--overview-table-cell.response--overview-table-header p {
  height: 70px;
} */

.response--overview-table-cell p {
  padding: 0 0.75rem;
  height: 100%;
  line-height: 44px;
}

.mapview-container {
  padding: 0 35px;
  /* margin: 0 auto; */
  overflow-y: auto;
}

.mapview-container #world-map {
  min-height: 500px;
  margin: 15px auto 0 auto;
  max-width: 1100px;
}

.response--overview-table-cell.response--overview-table-header p {
  line-height: normal;
  height: auto;
}

.scroll-container {
  overflow-y: auto;
  padding: 0 30px;
}

.x-tooltip {
  /* .style('position', `absolute`)
  .style('text-align', 'center')
  .style('opacity', 1)
  .style('background-color', `white`) */
  position: absolute;
  text-align: center;
  opacity: 1;
  background-color: rgba(50, 50, 50, 0.87);
  color: white;
  border-radius: 4px;
  max-width: 400px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4em;
  text-align: left;
  padding: 25px 25px;
}

.x-tooltip p:last-child {
  margin-bottom: 0;
}

.x-tooltip:after {
  top: 100%;
  content: ' ';
  display: block;
  height: 20px;
  left: calc(50% - 8px);
  position: absolute;
  border-color: rgba(50, 50, 50, 0.87) transparent transparent transparent;
  border-style: solid;
  border-width: 8px;
}

.map-legend {
  margin-top: 0 !important;
}

.extra-legend {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 400;
}

.table--results > tbody > tr > td {
  padding: 0 10px;
}

.table--results > tbody > tr > td:first-child {
  padding: 10px;
}

.logo-banner {
  height: 40px;
}

.country-profile-table tbody tr td {
  padding: 10px 4px;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
}

.download-profile {
  cursor: pointer;
}

.download-profile > .download-ic {
  width: 36px;
}

.download-profile > .download-ic-active {
  width: 0;
}

.download-profile:hover > .download-ic-active {
  width: 36px;
}

.download-profile:hover > .download-ic {
  width: 0;
}

@media screen {
  .media-print {
    visibility: hidden;
  }
}

@media print {
  .noprint {
    visibility: hidden;
    display: none;
  }

  /* * { overflow: visible !important; }  */
  html,
  body,
  #root,
  .results {
    overflow-y: visible;
    height: auto;
  }

  .print-section {
    padding: 30px 35px;
  }

  #root {
    height: 100% !important;
  }

  .response-overview {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .response-overview .horizontalscroll {
    margin-top: 10px;
  }

  .stattable-head {
    margin: 0;
  }

  .stattable-head > .extra-legend {
    font-weight: 400;
    font-size: 16px;
  }
}
